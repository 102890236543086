import React from "react";
import { useStaticQuery, graphql } from "gatsby"

/**
 * Sidebar additional downloads
 */
export default function AdditionalDownloads({title, downloads}) {

  //grab the downloads from the options page
  const data = useStaticQuery(graphql`
    query {
	  wp {
		acfOptionsOptions {
	      options {
	        widgetDownloads {
	          title
	          url
			  extension
	        }
	      }
	    }
	  }
    }
  `)

  if (!downloads || downloads.length===0) {
	return null;
  }

  return(
	<section className="additional-downloads">
		<h2>{title}</h2>
		<ul>
		  {downloads.map((document, i) => {
			//loop through the downloads from the options page and look for a match
			return (data?.wp?.acfOptionsOptions?.options?.widgetDownloads?.map((optionDownload) => {
		      return (document.download===optionDownload.title)
			  ? <li key={`document${i}`}>
				  <a href={optionDownload.url} rel="noreferrer">{optionDownload.title}<span>{optionDownload.extension}</span></a>
			 	</li>
	          : null
		    }))
		  })}
		</ul>
	</section>
  )
}

