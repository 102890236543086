import React, { useState, useEffect } from "react"
import { graphql, Link, navigate } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import Widgets from '../components/widgets/Widgets' 
import Faq from '../components/widgets/Faq'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import AdditionalDownloads from "../components/widgets/AdditionalDownloads";

export default function SidebarPage({ data, location }) {
  const page = data.allWpPage.nodes[0];
  const sections = page.sidebar?.sections;
  const parent = page.wpParent?.node;
  const children = page.wpChildren?.nodes;
  const [activeSection, setActiveSection] = useState(0);

  /**
   * Called when a form field changes
   */
  const handleChange = function(event) {
	const value = event.target.value;
	if (isNaN(value)) {
		//need to navigate to a url
		const parts = value.split(',');//the value in the option contains both the url and the index
		navigate(parts[0], {
          state: { section: parts[1] },
        });
	} else {
		selectSection(event.target.value);
	}
  }

  /*
   * When we select an area from the list on desktop
   */
  const selectSection = function(section) {
    setActiveSection(section);
	//on mobile scroll down		
	if (window && document) {			
		const target = document.getElementById('section'+section);
		if (target) {
		  window.scrollTo({
		    top: target.offsetTop,
		    behavior: 'smooth',
		  });
		}
	}
  }

  const TextSection = ({section, index}) => (
    <section className="text" id={`section${index}`}>
		<h2>{section.title}</h2>
		<div className="copy" dangerouslySetInnerHTML={{ __html: section.content }} />	
	</section>
  );

  const TableSection = ({section, index}) => (
	<section className="tables" id={`section${index}`} >
		<h2>{section.title}</h2>
		{section.tables?.length && section.tables.map((table, i) => (
			<div className="scroll1" key={`table${i}`}>
				<div className="scroll2">
					<div className="table">
						<h3>{table.subtitle}</h3>
						<div dangerouslySetInnerHTML={{ __html: table.table }} />	
					</div>
				</div>
			</div>
		))}
	</section>
  );

  const DocumentsSection = ({section, index, data}) => (
	<section className="documents" id={`section${index}`} >
		<h2>{section.title}</h2>
		<ul>
		  {section.documents?.length && section.documents.map((document, i) => {
			//loop through the downloads from the options page and look for a match
			return (data?.wp?.acfOptionsOptions?.options?.widgetDownloads?.map((optionDownload) => {
		      return (document.download===optionDownload.title)
			  ? <li key={`document${i}`} className="table">
				  <h3><a href={optionDownload.url} rel="noreferrer">{optionDownload.title}</a></h3>
				  <p>{optionDownload.extension}</p>
			 	</li>
	          : null
		    }))
		  })}
		</ul>
	</section>
  );

  const CriteriaAssessmentSection = ({section, index}) => (
	<div className="criteria-assessment" id={`section${index}`} >
		<Faq widget={section} />
			
		<AdditionalDownloads title={section.downloadsTitle} downloads={section.downloads} />			
		
	</div>
  );

  /*if we've just navigated from a child page we might want to select a section*/
  useEffect(() => {
    if (typeof location?.state?.section != 'undefined') {
      selectSection(location.state.section);
    }
  });

  return (
    <Layout className="page-sidebar">	
      <Seo post={page} />
	  <article>
	    <header className="page-widgets">
	      <Hero widget={{layout : 'inner', title: page.title, content: page.content}} />
	    </header>
		<div className="internal columns">
			<aside>
				<div className="sticky">
					<form>
						<p>
							<select name="area" value={activeSection} onChange={handleChange}>
								{/*if there's a parent page, add the section links*/}
								{parent?.sidebar?.sections?.length && parent.sidebar.sections.map((parent1, i) => (
									<option value={`${parent.uri},${i}`} key={`parent${i}`}>{parent1.title}</option>
								))}
							    {sections?.length && sections.map((section, i) => (
								    <option value={i} key={`section${i}`}>{section.title}</option>
							    ))}
								{/*also add links to any child pages*/}
								{children?.length>0 && children.map((child, i) => (
									<option value={`${child.uri},${i}`} key={`child${i}`}>{child.title}</option>
								))}
							</select>
						</p>
						<ul>
							{/*if there's a parent page, add the section links*/}
							{parent?.sidebar?.sections?.length && parent.sidebar.sections.map((parent1, i) => (
								<li key={`parent${i}`}>
									<Link to={parent.uri} state={{ section: i }} className="parent">{parent1.title}</Link>
								</li>
							))}
						    {sections?.length && sections.map((section, i) => (
							    <li key={`section${i}`} className={activeSection===i ? 'active' : ''}>
									<button type="button" className="plain" onClick={() => selectSection(i)}>{section.title}</button>
								</li>
						    ))}
							{/*also add links to any child pages*/}
							{children?.length>0 && children.map((child, i) => (
								<li key={`child${i}`}>
									<Link to={child.uri} state={{ section: i }} className="child">{child.title}</Link>
								</li>
							))}
						</ul>
					</form>
					{page.sidebar.sidebarAdditionalContent && (
						<div className="additional desktop" dangerouslySetInnerHTML={{ __html: page.sidebar.sidebarAdditionalContent }} />
					)}
				</div>
			</aside>
			
	    	<div className="content">
			    
			  {sections?.length && sections.map((section, index) => {
				
				switch(section.__typename) {
			     case 'WpPage_Sidebar_Sections_Text': return (
			         <TextSection key={ index } section={ section } index={index} />
			     );	
			     case 'WpPage_Sidebar_Sections_Table': return (
			         <TableSection key={ index } section={ section } index={index} />
			     );		
			     case 'WpPage_Sidebar_Sections_Documents': return (
			         <DocumentsSection key={ index } section={ section } data={data} index={index} />
			     );			
			     case 'WpPage_Sidebar_Sections_CriteriaAssessment': return (
			         <CriteriaAssessmentSection key={ index } section={ section } index={index} />
			     );	  
			     default: 
					return (<p style={{color: "red"}}>Section: {section.__typename}</p>)
			   }
		      })}
			  {page.sidebar.sidebarAdditionalContent && (
				<div className="additional mobile" dangerouslySetInnerHTML={{ __html: page.sidebar.sidebarAdditionalContent }} />
			  )}
			</div>
		</div>
	    {/*<InternalShapes page={page.slug} widget="SidebarPage" />*/}
		
		<Widgets widgets={page.widgets?.pageWidgets} />
		
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
	wp {
	  acfOptionsOptions {
	    options {
	      widgetDownloads {
	        title
	        url
			extension
	      }
	    }
	  }
	}
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
		slug
	    content
        ...PageSEOFragment
		wpChildren {
          nodes {
            ... on WpPage {
	          title
	          uri
	        }
          }
        }
	    wpParent {
	      node {
	        ... on WpPage {
              uri
			  title
	          sidebar {
	            sections {
	              ... on WpPage_Sidebar_Sections_Text {
	                title
	              }
	              ... on WpPage_Sidebar_Sections_Table {
	                title
	              }
	              ... on WpPage_Sidebar_Sections_Documents {
	                title
	              }
	            }
	          }
	        }
	      }
	    }
	    sidebar {
		  sidebarAdditionalContent	
	      sections {
			__typename
	        ... on WpPage_Sidebar_Sections_Text {
	          title
	          content
	        }
	        ... on WpPage_Sidebar_Sections_Table {
	          title
	          tables {
	            subtitle
	            table
	          }
	        }
	        ... on WpPage_Sidebar_Sections_Documents {
	          title
	          documents {
	            download
	          }
	        }
			... on WpPage_Sidebar_Sections_CriteriaAssessment {
	          title
	          searchBoxPlaceholder
	          accordion {
	            title
	            text
	          }
	          downloadsTitle
	          downloads {
	            download
	          }
	        }
	      }
	    }
		...WidgetFragment
      }
    }
  
  }
`